
import { createRouter, createWebHashHistory } from 'vue-router';

export default async ({ store }) => {
	let routes = [
		//Dashboard routes


		//news routes
		{
			path: '/news',
			alias: '/news/:fieldName/:fieldValue',
			name: 'newslist',
			component: () => import('./pages/news/list.vue'),
			props: true
		},


		{
			path: '/news/view/:id',
			name: 'newsview',
			component: () => import('./pages/news/view.vue'),
			props: true
		},

		{
			path: '/news/add',
			name: 'newsadd',
			component: () => import('./pages/news/add.vue'),
			props: true
		},

		{
			path: '/news/edit/:id',
			name: 'newsedit',
			component: () => import('./pages/news/edit.vue'),
			props: true
		},

		{
			path: '/news/feed',
			alias: '/news/feed/:fieldName/:fieldValue',
			name: 'newsfeed',
			component: () => import('./pages/news/feed.vue'),
			props: true
		},

		{
			path: '/news/hoodssummaryreport',
			alias: '/news/hoodssummaryreport/:fieldName/:fieldValue',
			name: 'newshoodssummaryreport',
			component: () => import('./pages/news/hoodssummaryreport.vue'),
			props: true
		},



		//users routes
		{
			path: '/users',
			alias: '/users/:fieldName/:fieldValue',
			name: 'userslist',
			component: () => import('./pages/users/list.vue'),
			props: true
		},


		{
			path: '/users/view/:id',
			name: 'usersview',
			component: () => import('./pages/users/view.vue'),
			props: true
		},

		{
			path: '/account/edit',
			name: 'usersaccountedit',
			component: () => import('./pages/account/accountedit.vue'),
			props: true
		},

		{
			path: '/account',
			name: 'usersaccountview',
			component: () => import('./pages/account/accountview.vue'),
			props: true
		},

		{
			path: '/users/add',
			name: 'usersadd',
			component: () => import('./pages/users/add.vue'),
			props: true
		},

		{
			path: '/users/edit/:id',
			name: 'usersedit',
			component: () => import('./pages/users/edit.vue'),
			props: true
		},

		//blockedusers routes
		{
			path: '/blockedusers/:index?/:fieldName?/:fieldValue?',
			name: 'blockeduserslist',
			component: () => import('./pages/blockedusers/list.vue'),
			props: true
		},


		{
			path: '/blockedusers/add',
			name: 'blockedusersadd',
			component: () => import('./pages/blockedusers/add.vue'),
			props: true
		},

		{
			path: '/blockedusers/edit/:id',
			name: 'blockedusersedit',
			component: () => import('./pages/blockedusers/edit.vue'),
			props: true
		},

		//webcontent routes
		{
			path: '/webcontent/:index?/:fieldName?/:fieldValue?',
			name: 'webcontentlist',
			component: () => import('./pages/webcontent/list.vue'),
			props: true
		},


		{
			path: '/webcontent/add',
			name: 'webcontentadd',
			component: () => import('./pages/webcontent/add.vue'),
			props: true
		},

		{
			path: '/webcontent/edit/:id',
			name: 'webcontentedit',
			component: () => import('./pages/webcontent/edit.vue'),
			props: true
		},




		//Password reset routes
		{
			path: '/index/forgotpassword',
			name: 'forgotpassword',
			component: () => import('./pages/index/forgotpassword.vue'),
			props: true
		},
		{
			path: '/index/resetpassword',
			name: 'resetpassword',
			component: () => import('./pages/index/resetpassword.vue'),
			props: true
		},
		{
			path: '/index/resetpassword_completed',
			name: 'resetpassword_completed',
			component: () => import('./pages/index/resetpassword_completed.vue'),
			props: true
		},



		{
			path: '/error/forbidden',
			name: 'forbidden',
			component: () => import('./pages/errors/forbidden.vue'),
			props: true
		},
		{
			path: '/error/notfound',
			name: 'notfound',
			component: () => import('./pages/errors/pagenotfound.vue'),
			props: true
		},
		{
			path: '/:catchAll(.*)',
			component: () => import('./pages/errors/pagenotfound.vue')
		}
	];


	let loginToken = store.getters["auth/getLoginToken"];
	if (loginToken) {	//Token is available, user might still be logged in
		try {
			//fetch user data for the first time and saves in the vuex store
			await store.dispatch("auth/getUserData");
		}
		catch (e) {
			store.dispatch("auth/logout"); //token must have expired
		}
	}


	let user = store.state.auth.user;
	if (user) {
		routes.push({
			path: '/',
			alias: '/home',
			name: 'home',
			component: () => import(`./pages/home/home.vue`),
			props: true
		});
	}
	else {
		routes.push({
			path: '/',
			alias: '/index',
			name: 'index',
			component: () => import('./pages/index/index.vue'),
			props: true
		});
	}


	const router = createRouter({
		history: createWebHashHistory(),
		routes,
	});

	router.beforeEach(function (to, from, next) {
		window.scrollTo(0, 0);
		next();
	});

	return router;
}


export const AppMenus = {

  navbarTopRightItems: [],
  navbarTopLeftItems: [],
  navbarSideLeftItems: [
    {
      "to": "/home",
      "label": "Home",
      "icon": "pi pi-home text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/news",
      "label": "News",
      "icon": "pi pi-list text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/webcontent",
      "label": "USSD Ads",
      "icon": "pi pi-money-bill text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/blockedusers",
      "label": "Blocked Users",
      "icon": "pi pi-user-minus text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/users",
      "label": "Users",
      "icon": "pi pi-users text-primary",
      "iconcolor": "",
      "target": "",

    }
  ],
  categoryItems: [
    { value: "Entertainment", label: "Entertainment" },
    { value: "Sports", label: "Sports" },
    { value: "General", label: "General" },
    { value: "Business", label: "Business" },
    { value: "Politics", label: "Politics" },
  ],
  tagItems: [
    { value: "Latest", label: "Latest" },
    { value: "Trending", label: "Trending" },
    { value: "Hot", label: "Hot" },
    { value: "Featured", label: "Featured" },
  ],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: '',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}
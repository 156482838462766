import { createStore } from "vuex"
import { pageComponents } from "./page_components.js"
import appStore from "./app_store.js";
import { auth } from "./auth.js"
import { news } from "./news.js"
import { users } from "./users.js"
import { blockedusers } from "./blockedusers.js"
import { webcontent } from "./webcontent.js"


const { state, getters, mutations, actions } = appStore;

const store = createStore({
	state,
	getters,
	mutations,
	actions,
	modules: {
		pageComponents,
		auth,
		news,
		users,
		blockedusers,
		webcontent,
	},
	// enable strict mode (adds overhead!)
	// for dev mode only
	//strict: process.env.DEV
});
export default store;